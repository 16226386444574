import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { DateToTimestampService } from 'src/app/services/date-to-timestamp.service';
import { QuotationsService } from 'src/app/services/quotations.service';
import { MapFormComponent } from 'src/app/main/components/map-form/map-form.component';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-petrol-station-form',
  templateUrl: './petrol-station-form.component.html',
  styleUrls: ['./petrol-station-form.component.scss']
})
export class PetrolStationFormComponent implements OnInit {

  selectedfile = null;
  reader: FileReader;
  selectedfile2 = null;
  reader2: FileReader;
  rides: any;
  petrolMangers: any;
  max: any = null
  min: any = null
  startDateFormat: any = null
  startIssueDateFormat: any = null
  petrolTypes: any

  isSubmit: boolean = false

  petrolStationForm: any = this.fb.group({
    address_line1: [, Validators.required],
    name: [,],
    petrol_types: [, Validators.required],
    lat: [, Validators.required],
    long: [, Validators.required],
    user_id: [,],
    attachment_attributes: [],
  });

  constructor(private dialogRef: MatDialogRef<PetrolStationFormComponent>,
    private dateToTimestamp: DateToTimestampService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private quotationsService: QuotationsService,
    private usersService: UsersService,
    private fb: FormBuilder,
    public translate: TranslateService,) {
    this.reader = new FileReader();
    this.reader2 = new FileReader();
  }

  setDateBox(event, name) {

    if (name == 'startDate') {
      this.startDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.min = event.target.value
    } else {
      this.startIssueDateFormat = this.dateToTimestamp.convertDateToTimestamp(event)
      this.max = event.target.value
    }

  }



  closeModal() {
    this.dialogRef.close();
  }

  addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  ngOnInit() {
    this.min = this.addOneDay(new Date())
    this.getPetrolTypes();
    this.getPetrolMangers();
    if (this.data != null) {
      this.petrolStationForm.patchValue({
        name: this.data.petrol_station?.name,
        address_line1: this.data.petrol_station?.address_line1,
        lat: this.data.petrol_station?.lat,
        long: this.data.petrol_station?.long,
        petrol_types: this.data.petrol_station?.petrol_types
      })
    }
  }


  OnFileSelected(event) {
    this.selectedfile = <File>event.target.files[0];
    if (this.selectedfile) {
      const fileTypes = ['jpg', 'png', 'jpeg', 'gif'];
      let name = this.selectedfile.name;
      let array = name.split('.');
      let fileType = array[array.length - 1];
      if (fileTypes.includes(fileType)) {
        this.reader.readAsDataURL(this.selectedfile);
        this.reader.onload = () => { };
      } else {
        this.translate
          .get('MARKETPLACES.messages.mass_file_not_available', {
            value: 'mass_file_not_available',
          })
          .subscribe((res: string) => {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '400px',
              disableClose: true,
              data: { message: res, state: false },

            });
            dialogRef.afterClosed().subscribe(() => {
              this.selectedfile = {}
            });
          });
      }
    }
  }

  infoFormFormatted() {
    // this.startDateFormat = this.petrolStationForm.get('license_expiration_date').value.getTime()
    // this.startIssueDateFormat = this.petrolStationForm.get('license_issue_date').value.getTime()

    this.petrolStationForm.patchValue({
      license_expiration_date: this.startDateFormat,
      license_issue_date: this.startIssueDateFormat,

    })

  }


  createPetrolStation() {
    this.isSubmit = true;
    if (this.petrolStationForm.valid) {
      let data = {
        petrol_station: {
          ...this.petrolStationForm.value
        }
      }
      if (this.selectedfile != null) {
        data.petrol_station.attachment_attributes =
        {
          name: "front",
          file: this.reader.result
        }
      }

      this.dialogRef.close(data);
    }
  }

  openMapForm() {
    const dialogRef = this.dialog.open(MapFormComponent, {
      width: '800px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((locationData) => {
      if (locationData != undefined) {
        const address = locationData.address;

        if (address.length == 0) {
          this.translate
            .get('MAP.messages.location_error', { value: 'location_error' })
            .subscribe((res: string) => {
              this.dialog.open(MessageFormComponent, {
                width: '400px',
                disableClose: true,
                data: { message: res, state: false },
              });
            });
        } else {
          this.petrolStationForm.patchValue({
            address_line1: address[0].formatted_address,
          });
        }
        this.petrolStationForm.patchValue({
          lat: locationData.lat,
          long: locationData.lng,
        });
      }
    });
  }

  getPetrolTypes() {
    // let param = {
    //   all: true
    // }
    // this.storeProfileService.getShopCategories(param).subscribe((res: any) => {
    //   this.shopCategories = res.data.items
    // })

    this.petrolTypes = ["92", "EV", "95"]
  }
  getPetrolMangers() {
    let param = {
      all: true,
      user_category: "petrol_station",
      role: "admin"
    }
    this.usersService.getDrivers(param).subscribe((res: any) => {
      this.petrolMangers = res.data.items
    })

  }

}
