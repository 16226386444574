<div class="component-loader" (errorCallback)="getPetrolStation()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_petrol_stations" | translate }}</h2>
      <div>
        <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="update()"> {{
          "COMMON.edit" | translate }} </button>
        <button class="btn btn-default mb-2" (click)="backTolist()" *ngIf="tripId == null">
          {{ "DRIVERS.actions.back_petrol_stations" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>
        <button class="btn btn-default mb-2" (click)="backToDetails()" *ngIf="tripId != null">
          {{ "DRIVERS.actions.back_to_trip_details" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>



      </div>
    </div>
    <div class=" grid grid-cols-4 gap-3 p-4">
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.name" | translate }}</p>
        <span class="tabel-content"> {{ petrol_station?.name }}</span>
      </div>
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "USER.extras.image" | translate }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="petrol_station?.image_url" />
        </span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "WAREHOUSES.attributes.address.label" | translate }} <i class='bx bx-copy share-io pointer'
            (click)="copyAddress(petrol_station)"></i></p>
        <span class="tabel-content">
          {{ petrol_station?.address_line1 }}
        </span>
      </div>
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.fuels" | translate }}</p>
        <span class="tabel-content">
          {{ petrol_station?.petrol_types }}
        </span>
      </div>





    </div>

  </div>


  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_petrol_workers" | translate }}</h2>
      <div *ngIf="petrol_station?.user == null">
        <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="assignDriver()"> {{
          "COMMON.assign" | translate }} </button>

      </div>
    </div>

    <ng-container>
      <!-- <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
        [class.open-filter]="isFilter">




      </div> -->
      <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
      </app-pagination>
      <div class="component-loader" (errorCallback)="getStationWorkers()"
        [errorMessage]="'lOADER.errorMessage' | translate " component-loader [componentError]="loadingError"
        [loadingMessage]="'lOADER.loadingMessage' | translate" [componentLoading]="loading">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                {{ "DRIVERS.extras.name" | translate }}
              </th>


              <th scope="col">
                {{ "DRIVERS.extras.phone" | translate }}
              </th>
              <th scope="col">
                {{ "DRIVERS.extras.email" | translate }}
              </th>


              <!-- <th scope="col">
                {{
                "BANKACOUNTS.extras.transaction_type"
                | translate
                }}
              </th> -->

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of workers | paginate: paginationConfig">
              <td class="pointer weight-label" (click)="goToDetails(item.id)">
                {{ item?.name }}
              </td>


              <td>
                {{ item?.phone }}
              </td>

              <td>
                {{ item?.email }}
              </td>
              <!-- <td>
                {{ ("QUOTION.attributes.status." + item?.status) | translate }}
              </td> -->

            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <!--
    <div class=" grid grid-cols-4 gap-3 p-4" *ngIf="petrol_station?.user != null">

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.user_driver" | translate }}</p>
        <span class="tabel-content pointer" (click)="openDriverDetails(petrol_station?.user?.id)">
          {{ petrol_station?.user?.name }}
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.phone" | translate }}</p>
        <span class="tabel-content">
          {{ petrol_station?.user?.phone }}
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.profile"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="petrol_station?.user?.image_url" />
        </span>
      </div>





    </div> -->

  </div>

</div>
